import React from 'react'

const SummerProgram = () => {
    return (
        <>
            <section className="SummerSec">
                <div className="container">
                    <div className="row">
                        <div className="col-12 headingdiv">
                            <h2 className="YellowHeading">
                            Details about our MCA, BCA, MSc IT, and BSc IT  Internship Program 2025
                            </h2>
                            <p>Dive Deeper: Unveiling the Details of Your Unforgettable Summer Internship Experience – Explore, Learn, and Shape Your Future!</p>
                        </div>
                    </div>
                    <div className="row gy-4">
                        <div className="col-md-4 col-12">
                            <div className="SummerDiv first">
                                <h3>MCA INTERNSHIP START DATES</h3>
                                <ul>
                                    <li>06th January, 2025</li>
                                    <li>20th January, 2025</li>
                                    <li>1st febuary, 2025</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="SummerDiv second">
                                <h3>DURATION OF INTERNSHIP PROGRAM​</h3>
                                <ul>
                                    <li>6 months</li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="SummerDiv third">
                                <h3>DELIVERABLES FOR 6 MONTH PROGRAM​
                                ​</h3>
                                <ul>
                                    <li>– Industry Recognised Internship (Project) Certificate</li>
                                    <li>– Training Certificate from Upflairs Pvt Ltd</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SummerProgram