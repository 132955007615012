import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {
  BrowserRouter,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Layout from "./Layout";
import Home from "./Pages/Home";
import Linux from "./Pages/Linux";
import Campus from "./Pages/Campus";
import PaymentSuccess from "./Pages/PaymentSuccess";
import McaInternship from "./Pages/MCApage";
import { HelmetProvider } from 'react-helmet-async';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route path="" element={<Home />} />
      <Route path="payment-success" element={<PaymentSuccess />} />
      <Route path="linux" element={<Linux />} />
      <Route path="campus" element={<Campus />} />
      <Route path="mca-internship" element={<McaInternship />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>    
    <RouterProvider router={router} />
  </HelmetProvider>
  </React.StrictMode>
);
