import React, { useState, useEffect } from "react";
import logopng from "../images/White_Logo.png";
import { NavLink } from "react-router-dom";
import EnrollForm from "./Sections/EnrollForm";

const Navbar = () => {
  const [IsModelOpen, setIsModelOpen] = useState(false);

  const FormOpenBTN = () => {
    // setIsModelOpen(true);
    const paymentSec = document.getElementById("register-section");
    paymentSec.scrollIntoView({ block: "end" });
  };

  const closeModel = () => {
    setIsModelOpen(false);
  };

  return (
    <>
      <header className="MainHaeder" id="main_navbar">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <NavLink className="navbar-brand" to={"https://upflairs.com/"}>
              <img src={logopng} alt="logo" />
            </NavLink>
            <button
              class="navbar-toggler border-0"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-mobile-menu menuEffact" id="mainmenu"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                <li className="nav-item NavbarDropdown DropdownButtonClk">
                  <NavLink to={"javascript:void(0)"} className="nav-link">
                    We are here
                    <i class="fa-solid fa-chevron-down"></i>
                  </NavLink>
                  <ul className="DropdownMenu DropdownUL">
                    <li>
                      <NavLink to={"https://summers.upflairs.com/campus"}>
                        Campus Ambassador
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"https://upflairs.com/about"}>
                        Know Us
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"https://upflairs.com/flair-stories"}>
                        Flair Stories
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"https://upflairs.com/careers"}>
                        Make career with us
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"https://upflairs.com/in-campus-training"}>
                        In-Campus Training
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"https://upflairs.com/hands-on-workshop"}>
                        Hands-on Workshop
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="nav-item NavbarDropdown DropdownButtonClk">
                  <NavLink to={"javascript:void(0)"} className="nav-link">
                    Skill Based Programs
                    <i class="fa-solid fa-chevron-down"></i>
                  </NavLink>
                  <ul className="DropdownMenu DropdownUL">
                    <li>
                      <NavLink
                        to={
                          "https://upflairs.com/courses/machine-learning-data-science-in-jaipur"
                        }
                      >
                        Data Science with Machine Learning & AI (4 -6 Months)
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={
                          "https://upflairs.com/courses/full-stack-web-development-in-jaipur"
                        }
                      >
                        Full Stack Web Development (4 -6 Months)
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={
                          "https://upflairs.com/courses/cloud-computing-devops-enigneering-in-jaipur"
                        }
                      >
                        DevOps & Cloud Engineering (4 -6 Months)
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"https://summers.upflairs.com/linux"}
                        className={({ isActive }) =>
                          `${isActive ? "active" : ""}`
                        }
                      >
                        Linux
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="nav-item NavbarDropdown DropdownButtonClk">
                  <NavLink to={"javascript:void(0)"} className="nav-link">
                    Training Programs
                    <i class="fa-solid fa-chevron-down"></i>
                  </NavLink>
                  <ul className="DropdownMenu DropdownUL">
                    <li>
                      <NavLink
                        to={  "/"    }
                      >
                       Summer Industrial Training Program 
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={
                          "mca-internship"
                        }
                      >
                        MCA Internship
                      </NavLink>
                    </li>
                   
                  </ul>
                </li>
                
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to={"https://upflairs.com/dashboard"}
                  >
                    Dashboard
                  </NavLink>
                </li>
              </ul>
              <div className="d-flex">
                <button
                  className="WhiteBTN"
                  type="submit"
                  onClick={FormOpenBTN}
                >
                  Enroll Now
                </button>
              </div>
            </div>
          </div>
        </nav>
      </header>

      {/* {IsModelOpen && <EnrollForm closeModel={closeModel} />} */}
    </>
  );
};

export default Navbar;
