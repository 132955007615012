import React from "react";
// import AkashSir from "../../images/akash_sir.jpeg";
import RanjitSir from "../../images/trainers/Ranjeet_Sir.jpg";
import SidharthSir from "../../images/trainers/sidharthSir.jpeg";
import AshuSir from "../../images/trainers/AshuSirImg.png";
import AnkurSir from "../../images/trainers/AnkurSir.jpeg";
import rajendrajangid from "../../images/trainers/rajendrajangid.png";
import jaikishan from "../../images/trainers/jaikishan.JPG";
import govind from "../../images/trainers/govind.jpeg";
import akashsir from "../../images/trainers/akahssir123.jfif";


const OurTrainer = () => {
  return (
    <>
      <section className="ExpertTrainSec">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 class="YellowHeading">Our Expert Team</h2>
            </div>
          </div>
          <div className="row gy-5">
            <div className="col-lg-6 col-12">
              <div className="TeamDiv">
                <div className="Fir">
                  <div className="imgDiv">
                    <img src={AshuSir} alt="teacher img" />
                  </div>
                </div>
                <div className="Sec">
                  <h3>Ashutoshh Singh</h3>
                  <h6>CTO & Corporate Trainer</h6>
                  <p>
                    Ashutoshh's innovative vision, technical expertise, and
                    unwavering dedication have played a pivotal role in driving
                    the company's business development and growth. His ability
                    to navigate the ever-evolving tech landscape has not only
                    kept the company at the forefront but has also inspired the
                    entire team.
                  </p>
                  <span>
                    His commitment to knowledge-sharing and skill development
                    has empowered the team to excel. His training programs have
                    not only equipped individuals with essential skills but have
                    also contributed significantly to the overall professional
                    growth of the organization.
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="TeamDiv">
                <div className="Fir">
                  <div className="imgDiv">
                    <img src={SidharthSir} alt="teacher img" />
                  </div>
                </div>
                <div className="Sec">
                  <h3>Siddharth Singh</h3>
                  <h6>
                    Founder & Director (Upflairs Pvt Ltd), Embedded Systems,
                    Robotics & IoT Expert
                  </h6>
                  <p>
                    Siddharth is having an experience of 10+ years in the field
                    of IoT , Embedded System & Robotics.Siddharth being the
                    company's director, not only manages the company well but is
                    also a wonderful Instructor. With years of industry
                    experience and a passion for innovation,he brings
                    unparalleled expertise to our courses and learning to the
                    students.
                  </p>
                  <p>
                    His Key expertise are - AVR MCUs, Peripherals
                    (ADC/Timer/Counter/Interrupts), Communication Protocols
                    (USART/SPI/I2C/USB/GSM/GPS/RFID), Sensors (Digital/Analog),
                    IDEs (Keil/AVR Studio/IAR), Simulation (Proteus),PCB
                    Designing (PCB express/Proteus), Raspberry Pi, Arduino
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="TeamDiv">
                <div className="Fir">
                  <div className="imgDiv">
                    <img src={akashsir} alt="teacher img" />
                  </div>
                </div>
                <div className="Sec">
                  <h3>Akash Gaur</h3>
                  <h6>Sr.Devops Instructor</h6>
                  <p>
                    Akash is having an experience of 8+ Years and is an expert
                    at the forefront of cloud computing and DevOps
                    methodologies, equipping our learners with the skills to
                    navigate and optimize cloud environments efficiently. Our
                    instructor not only possess in-depth knowledge of cloud
                    platforms and DevOps practices but also have a passion for
                    teaching. He guide our students in harnessing the power of
                    cloud technologies, ensuring he is well-prepared to thrive
                    in the fast-evolving world of IT and infrastructure
                    management.
                  </p>
                  <span>
                    His Key expertise are - Linux , Cloud (like AWS, microsoft
                    azure , GCP ), DevOps tools (like docker , kubernetes ,
                    jenkins , ansible , terraform ) , monitoring
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="TeamDiv">
                <div className="Fir">
                  <div className="imgDiv">
                    <img src={AnkurSir} alt="teacher img" />
                  </div>
                </div>
                <div className="Sec">
                  <h3>Ankur Singh</h3>
                  <h6>Full Stack Instructor</h6>
                  <p>
                    Our web development trainer is a highly skilled and passionate professional with over 5+ years of experience in full-stack web development. They specialize in teaching and mentoring students to master a comprehensive range of front-end and back-end technologies, equipping them to build dynamic, robust, and scalable web applications.
                  </p>
                  <span>
                    Front-End: HTML5, CSS3, JavaScript (ES6+), Bootstrap, Tailwind CSS, React.js, Angular, TypeScript
                    Back-End: Node.js, Django, Express.js, Flask, RESTful APIs, GraphQL
                    Databases: MongoDB, MySQL, PostgreSQL, Firebase, SQLite
                    Version Control: Git, GitHub, Bitbucket
                    DevOps & Deployment: Docker, Kubernetes, AWS, Heroku, Netlify, Vercel
                    Other Tools & Frameworks: Webpack, Babel, Redux, Next.js, jQuery, Material-UI
                    This comprehensive skill set ensures students receive well-rounded training, preparing them to excel in the competitive world of web development.
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="TeamDiv">
                <div className="Fir">
                  <div className="imgDiv">
                    <img src={RanjitSir} alt="teacher img" />
                  </div>
                </div>
                <div className="Sec">
                  <h3>Ranjit Singh</h3>
                  <h6>Data Science Instructor</h6>
                  <p>
                    Proficient in C, C++, DSA, PYTHON, AI, ML, DL, NLP, CV, SQL, and NoSQL with 3+ years of experience in the development and implementation of data-driven systems for enhanced accuracy. Expertise lies in analyzing and interpreting text data, as well as evaluating emerging and researching NLP technologies. Proven track record of successful collaboration within cross-functional teams to deliver innovative solutions.
                  </p>
                  <span>
                    Programming: Python, Java, SQL, C, C++, HTML, CSS
                    Databases: MySQL, MongoDB, AWS RDS, S3
                    Libraries & Frameworks: Pandas, NumPy, TensorFlow, PyTorch, Flask, Transformers
                    Tools: Power BI, Excel, Web Scraping
                    AI & Data Science: Machine Learning, Deep Learning, NLP, Computer Vision, EDA, Time Series Analysis
                    MLOps & Cloud: Docker, Jenkins, CI/CD, AWS
                    Operating Systems: Windows, Linux
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="TeamDiv">
                <div className="Fir">
                  <div className="imgDiv">
                    <img src={rajendrajangid} alt="teacher img" />
                  </div>
                </div>
                <div className="Sec">
                  <h3>Rajendra jangid</h3>
                  <h6>Full Stack Instructor</h6>
                  <p>
                    Our web development trainer is proficient and deeply passionate about teaching full-stack web development. With over 3.5+ years of experience, they guide students in mastering both front-end and back-end technologies, empowering them to build robust and scalable web applications.
                  </p>
                  <span>
                    His Key expertise are - HTML 5, CSS 3.0, Javascript with ES
                    6, Bootstrap, React.Js, Node.js, Django, Databases- Mongodb,
                    My-Sql
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="TeamDiv">
                <div className="Fir">
                  <div className="imgDiv">
                    <img src={jaikishan} alt="teacher img" />
                  </div>
                </div>
                <div className="Sec">
                  <h3>Jai Krishan</h3>
                  <h6>Data Science Instructor</h6>
                  <p>
                    Jai Krishan Holds an experience of 2+ years and is highly expert
                    in the field of data science, possessing a wealth of
                    knowledge and experience in data analysis, machine learning,
                    and statistical modeling. With a keen understanding of
                    industry trends and a passion for teaching, our Data Science
                    Instructor is at the forefront of empowering the students
                    with the tools and knowledge needed to excel in this rapidly
                    evolving field.
                  </p>
                  <span>
                    His Key expertise are - Python, Artificial Intelligence,
                    Machine Learning, Deep Learning, Natural language
                    Programing, Computer Vision , SQL, Generative AI , LLM ,
                    Mlops , Cloud and NOSQL
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="TeamDiv">
                <div className="Fir">
                  <div className="imgDiv">
                    <img src={govind} alt="teacher img" />
                  </div>
                </div>
                <div className="Sec">
                  <h3>Govind Kumawat</h3>
                  <h6>Data Science Instructor</h6>
                  <p>
                    Govind Kumawat is an accomplished Data Science professional with over 1.5+ years of experience, specializing in data analysis, machine learning, and statistical modeling. With a deep understanding of industry trends and a passion for teaching, he empowers students with the skills and knowledge needed to thrive in the dynamic and rapidly evolving field of data science.
                  </p>
                  <span>
                    Programming & Core Skills: Python, SQL, NoSQL
                    Machine Learning & AI: Artificial Intelligence, Machine Learning, Deep Learning, Generative AI, Large Language Models (LLMs)
                    Specialized Domains: Natural Language Processing (NLP), Computer Vision
                    MLOps & Cloud: MLOps, Cloud Computing
                  </span>
                </div>
              </div>
            </div>


          </div>
        </div>
      </section>
    </>
  );
};

export default OurTrainer;
