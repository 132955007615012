import React from 'react';
import ReactPlayer from 'react-player';
// import Testimonial from '../../images/Testimonial_Video.mp4';

const WhyStudentLove = () => {

    const Testimonial1 = "https://youtu.be/-VOIo5pRcoM?si=owF10QX7ua_3d09X";
    const Testimonial2 = "https://www.youtube.com/watch?v=gFXAcBPRKDM";
    const Testimonial3 = "https://www.youtube.com/watch?v=qUbvmwGNJ6I";
    const Testimonial4 = "https://youtu.be/-qGesuPXZMk?si=Z4TgMqnxN6Ibe_9A";

    return (
        <>
            <section className="WhyStuLoveSec">
                <div className="container">
                    <div className="row">
                        <div class="col-12">
                            <h2 class="Headings text-center">Voice of Success</h2>
                            <p class="SubHeading text-center">Hear What Our MCA Interns Have to Say - Real Experiences, Real Growth!
                            </p>
                        </div>
                    </div>
                    <div className="row gy-4">
                        <div className="col-md-3 col-12">
                            <div className="VideoDiv">
                                <ReactPlayer className="MainVideo" url={Testimonial1} controls={false} />
                                <h4>Aditi Singh</h4>
                                <h6>Banasthali Vidyapith</h6>
                                <p>Aditi Singh , A student of Banasthali Vidyapith and currently Placed at Deloitte is here to share her reviews on the 4-6 Months course on Embedded System and IoT.|</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-12">
                            <div className="VideoDiv">
                                <ReactPlayer className="MainVideo" url={Testimonial2} controls={false} />
                                <h4>Kaustubh Swami</h4>
                                <h6>JECRC foundation</h6>
                                <p>Kaustubh Swami , A student of JECRC Foundation is here to share her reviews on the 4-6 Months course on Devops & Cloud Computing.</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-12">
                            <div className="VideoDiv">
                                <ReactPlayer className="MainVideo" url={Testimonial3} controls={false} />
                                <h4>Abhishek Singh</h4>
                                <h6>JECRC foundation</h6>
                                <p>Abhishek Singh , A student of JECRC Foundation is here to share her reviews on the 4-6 Months course on DevOps & Cloud Computing.</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-12">
                            <div className="VideoDiv">
                                <ReactPlayer className="MainVideo" url={Testimonial4} controls={false} />
                                <h4>Kaustubhi</h4>
                                <h6>Banasthali Vidyapith</h6>
                                <p>Kaustubhi, A student of Banasthali Vidyapith is here to share her reviews on the Summer Internship on Embedded System and IoT.</p>
                            </div>
                        </div>
        
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhyStudentLove