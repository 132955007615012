import React, { forwardRef, useState } from "react";
import Courses1 from "../../images/Courses(1).png";
import Courses2 from "../../images/Courses(2).png";
import Courses3 from "../../images/Courses(3).png";
import Courses4 from "../../images/Courses(4).png";
import CurriculumForm from "./CurriculumForm";
import EnrollForm from "./EnrollForm";

const courseData = [
  {
    img: Courses3,
    title: "Data Science with ML & AI",
    duration: "4 to 6 Months",
    details: "Explore Python, Databases, Machine Learning, Deep Learning, Computer Vision, and Natural Language Processing in our comprehensive program.",
    id: 1,
  },
  {
    img: Courses4,
    title: "Full Stack Web Development",
    duration: "4 to 6 Months",
    details: "Delve into Full Stack Web Development with our comprehensive program covering HTML, CSS, JavaScript, Front-end frameworks, Back-end frameworks, and Database management.",
    id: 2,
  },
  {
    img: Courses2,
    title: "DevOps & Cloud Computing",
    duration: "4 to 6 Months",
    details: "Immerse yourself in Cloud Computing and DevOps with our program covering Cloud Platforms, Infrastructure as Code, Containerization, Continuous Integration/Continuous Deployment (CI/CD), Monitoring, and Orchestration.",
    id: 3,
  },
];

const Coureses = forwardRef((props, ref) => {
  const [curriculum, setCurriculum] = useState({
    isOpen: false,
    courseId: null,
  });

  const openCurriculum = (courseId) => {
    setCurriculum({
      isOpen: true,
      courseId: courseId,
    });
  };

  const [IsModelOpen, setIsModelOpen] = useState(false);

  const FormOpenBTN = () => {
    const paymentSec = document.getElementById("register-section");
    paymentSec.scrollIntoView({ block: "end" });
  };

  const closeModel = () => {
    setIsModelOpen(false);
  };

  return (
    <>
      <section ref={ref} className="CoursesSec" id="CourseID">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="Headings text-center">
                Our MCA/BCA/ MSC IT / BSC IT Courses
              </h2>
            </div>
            <div className="col-12">
              <div className="CoursesDiv CoursesDiv1">
                <ul>
                  {courseData.map((course) => (
                    <li style={{width:'30%',}} key={course.id}>
                      <div className="icn">
                        <img src={course.img} alt="course" />
                        <div className="con">{course.title}</div>
                      </div>
                      <div className="conLI">
                        <ol>
                          <li>Duration - {course.duration}</li>
                          <li>{course.details}</li>
                        </ol>
                      </div>
                      

                      <button
                        type="button"
                        onClick={() => openCurriculum(course.id)}
                        className="curriculumBTN"
                      >
                        Download Curriculum
                      </button>
                      
                    </li>
                  ))}
                </ul>
                <button
                  className="YellowBTN mt-4 m-auto d-flex justify-content-center"
                  onClick={FormOpenBTN}
                >
                  Enroll Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {curriculum.isOpen && (
        <CurriculumForm
          closeCurriculum={() => {
            setCurriculum({ isOpen: false, courseId: null });
          }}
          courseId={curriculum.courseId}
        />
      )}
      {/* {IsModelOpen && <EnrollForm closeModel={closeModel} />} */}
    </>
  );
});

export default Coureses;

